export enum CoinID {
  BRL = 1,
  USDT = 2,
  USDC = 3,
  USD = 4,
  ETH = 5,
  BTC = 6,
  BNB = 7,
  SHIB = 8,
  DOGE = 9,
  XRP = 10,
  MATIC = 11,
  EUR = 12,
  MXN = 13,
  COP = 14,
  CLP = 15,
  ARS = 16,
  TRX = 17,
  SOL = 18,
  INR = 19,
}
