import { FocusEvent, ForwardedRef, useEffect, useRef, useState } from 'react';
import { InputProps } from './props';
import { FieldValues } from 'react-hook-form';
import styles from './styles.module.scss';
import { useToggle } from '@package/hooks';
import { fixedForwardRef } from '@package/utils';

function InputComponent<T extends FieldValues>(
  {
    isError = false,
    isContainerColor,
    errorMessage,
    onFocus,
    onBlur,
    name,
    register,
    icon,
    registerOptions = {},
    showEraserButton,
    onErase,
    isLoading = false,
    readOnly,
    hideLock,
    isCardColor,
    showSpinner,
    hasPaddingRight,
    hasPaddingLeft,
    focusOnMount,
    className = '',
    customIcon,
    rightIcon,
    type,
    secondarySkeleton = true,
    ...props
  }: InputProps<T>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [showPassword, toggleShowPassword] = useToggle();
  const inputRef = useRef<HTMLInputElement>(null);

  function handleFocus(e: FocusEvent<HTMLInputElement, Element>) {
    onFocus?.(e);
    setOpenTooltip(false);
  }

  function handleBlur(e: FocusEvent<HTMLInputElement, Element>) {
    onBlur?.(e);
    setOpenTooltip(true);
  }

  function getType(): string {
    switch (type) {
      case 'switch':
        return 'checkbox';
      case 'password':
        return showPassword ? 'text' : 'password';
      default:
        return type;
    }
  }

  function resetSearchValue(): void {
    if (inputRef?.current) {
      inputRef.current.focus();
      inputRef.current.value = '';
    }

    onErase?.();
  }

  function handleOpenTooltip() {
    if (!isError) {
      return;
    }

    setOpenTooltip(true);
  }

  useEffect(handleOpenTooltip, [isError]);

  function handleMountFocus() {
    if (!focusOnMount || !inputRef?.current) {
      return;
    }

    inputRef?.current?.focus();
  }

  useEffect(handleMountFocus, [focusOnMount, inputRef]);

  if (isLoading) {
    return (
      <div
        className={`${styles.skeleton} ${secondarySkeleton && !isContainerColor ? styles['skeleton--secondary'] : styles['skeleton--default']} ${!!type ? styles[`skeleton--${type}`] : ''}`}
      />
    );
  }

  return (
    <div
      className={`${styles.container} ${!!type ? styles[`container--${type}`] : ''} ${className ? className : ''}`}
    >
      <div
        className={`${styles.container__relative} ${!!type ? styles[`container__relative--${type}`] : ''}`}
      >
        <input
          readOnly={readOnly}
          className={`${styles.container__input} ${!!icon || !!customIcon ? styles['container__input--figure'] : ''} ${hasPaddingRight ? styles['container__input--figure--right'] : ''} ${hasPaddingLeft ? styles['container__input--padding-left'] : ''} ${isContainerColor ? styles['container__input--container-color'] : ''} ${isCardColor ? styles['container__input--card-color'] : ''} ${isError && openTooltip ? styles['container__input--error'] : ''} ${!!type ? styles[`container__input--${type}`] : ''} ${className}`}
          ref={ref ?? inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          spellCheck={false}
          name={name}
          type={getType()}
          style={{
            //@ts-ignore
            '--position':
              type == 'range'
                ? `${(Number(props?.value ?? 0) / Number(props?.max)) * 100}%`
                : undefined,
          }}
          {...props}
          {...(register &&
            name &&
            register?.(name, {
              ...registerOptions,
              onBlur: e => {
                registerOptions?.onBlur?.(e);
                handleBlur(e);
              },
            }))}
        />
        {['checkbox', 'radio'].includes(type as string) && (
          <span
            className={`${styles.container__input__checkmark} ${type == 'radio' ? styles['container__input__checkmark--radio'] : ''}`}
          />
        )}
        {type == 'switch' && (
          <span className={styles.container__input__switch__slider} />
        )}
        {type == 'range' && (
          <div
            className={styles.container__input__indicator}
            style={{
              //@ts-ignore
              '--indicator-position': `${(Number(props?.value ?? 0) / Number(props?.max)) * 100}%`,
            }}
          >
            {props?.value}
          </div>
        )}
        {icon && !showSpinner && (
          <i
            className={`${styles.container__figure} ${hasPaddingRight ? styles['container__figure--right'] : ''} ${icon}`}
          />
        )}

        {customIcon && !showSpinner && customIcon}

        {showEraserButton && (icon ? true : !showSpinner) && (
          <button
            className={styles.container__eraser}
            onClick={resetSearchValue}
            type="button"
          >
            <i className="fa-solid fa-xmark-large" />
          </button>
        )}

        {(readOnly || props?.disabled) &&
          !hideLock &&
          !['checkbox', 'radio'].includes(type as string) && (
            <i
              className={`${styles.container__figure} ${styles['container__figure--right']} fa-solid fa-lock ${!!type ? styles[`container__figure--${type}`] : ''}`}
            />
          )}
        {rightIcon && !hideLock && (
          <i
            className={`${styles.container__figure} ${styles['container__figure--right']}  ${rightIcon}`}
          />
        )}

        {type === 'password' && !icon && !rightIcon && (
          <i
            className={`${styles.container__figure} ${styles['container__figure--right']} ${styles['container__figure--password']} ${showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}`}
            onClick={toggleShowPassword}
          />
        )}

        {showSpinner && (
          <div
            className={`${styles.container__figure} ${!icon ? styles['container__figure--right'] : ''}`}
          >
            <i className="fas fa-circle-notch fa-spin" />
          </div>
        )}
      </div>

      {isError &&
        openTooltip &&
        !!errorMessage &&
        (Array.isArray(errorMessage) ? (
          errorMessage.map((error, i) => (
            <span
              className={styles['container__input-error']}
              key={`error-message-${i}`}
            >
              *{error}
            </span>
          ))
        ) : (
          <span className={styles['container__input-error']}>
            *{errorMessage}
          </span>
        ))}

      {type == 'range' && (
        <>
          <div className={styles.container__value}>{props?.value}</div>
          <div
            className={`${styles.container__value} ${styles['container__value--right']}`}
          >
            {props?.max}
          </div>
        </>
      )}
    </div>
  );
}

export const Input = fixedForwardRef(InputComponent);
