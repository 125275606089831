import { StepsProps } from './props';
import styles from './styles.module.scss';
import Step from './Step';
import { useMemo } from 'react';
import { useCustomCarousel } from '@package/hooks';

export function Steps<V>({
  currentStep,
  options,
  blockFurtherSteps,
  i18nWorkspace,
  currentIndex,
  isLoading,
  onStepClick,
}: StepsProps<V>) {
  const {
    containerRef,
    handleMouseDown,
    handleMouseLeave,
    handleMouseUp,
    handleMouseMove,
  } = useCustomCarousel();
  const MemorizedSteps = useMemo(
    () =>
      options?.map((opt, index) => (
        <Step
          currentStep={currentStep}
          i18nWorkspace={i18nWorkspace}
          option={opt}
          currentIndex={currentIndex}
          index={index}
          onStepClick={onStepClick}
          isLastItem={index < options.length - 1}
          key={`step-${opt.label}-${index}`}
          blockFurtherSteps={blockFurtherSteps}
          isLoading={isLoading}
        />
      )),
    [
      options,
      currentStep,
      i18nWorkspace,
      currentIndex,
      blockFurtherSteps,
      isLoading,
      onStepClick,
    ],
  );

  return (
    <div
      className={styles.container}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {MemorizedSteps}
    </div>
  );
}
