import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { UseIndicatorProps } from './props';

export function useIndicator<T>({
  tabs,
  isDisabled,
  currentTab,
  containerRef,
}: UseIndicatorProps<T>): MutableRefObject<HTMLDivElement> {
  const position = useMemo(
    () => tabs.findIndex(tab => tab.value == currentTab),
    [tabs, currentTab],
  );

  const indicatorRef: MutableRefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);

  const handleAdjustIndicator = useCallback(() => {
    if (!indicatorRef.current || !containerRef.current || isDisabled) return;

    const tabsContainer = containerRef.current;
    const indicatorEl = indicatorRef.current;
    const tabButtons = tabsContainer.querySelectorAll('button');

    if (!tabButtons?.[position]) {
      indicatorEl.style.width = '0px';
      return;
    }

    const tabEl = tabButtons[position];
    const left = tabEl.offsetLeft;
    const width = tabEl.offsetWidth;

    indicatorEl.style.width = `${width}px`;
    indicatorEl.style.transform = `translateX(${left}px)`;
  }, [position, isDisabled, containerRef]);

  function onMount() {
    handleAdjustIndicator();

    window.addEventListener('resize', handleAdjustIndicator);

    return () => {
      window.removeEventListener('resize', handleAdjustIndicator);
    };
  }

  useEffect(onMount, [handleAdjustIndicator]);

  return indicatorRef;
}
