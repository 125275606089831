import { FieldValues } from 'react-hook-form';
import styles from './styles.module.scss';
import { DatePickerProps } from '../props';
import { useCustomDatePicker } from '@package/hooks';
import { FormGroup, SelectInput } from '@package/components';
import useTranslation from 'next-translate/useTranslation';
import { dateFormatMapping } from '@package/src/constants/date/date-format-mapping';
import { Language } from '@package/src/enums/language';
import { useMediaQuery } from '@mui/system';

export default function DatePickerDesktop<T extends FieldValues>({
  onChange,
  value,
  disabled,
  readOnly,
  isLoading,
  ...props
}: DatePickerProps<T>) {
  const { t } = useTranslation('common');
  const isMaxHeight = useMediaQuery('(max-height: 1000px)');
  const {
    days,
    months,
    years,
    current: { day, month, currentYear: year },
    updateDay,
    updateMonth,
    updateYear,
  } = useCustomDatePicker({
    date: new Date((value as string) ?? new Date()).toString(),
    callback: onChange,
    isLoading: props?.showSpinner || isLoading,
  });

  const { lang } = useTranslation();
  const formatMapping = dateFormatMapping.get(
    (lang as Language) ?? Language.English,
  );

  return (
    <div className={styles.container}>
      <FormGroup
        label={t('day')}
        className={`${styles.container__label} ${styles[`container__label--order-${formatMapping?.day}`]}`}
      >
        <SelectInput
          getOptionLabel={opt => String(opt)}
          getOptionValue={opt => opt}
          options={days}
          value={day + 1}
          cardBg
          popLayout={!isMaxHeight}
          invertOptionsPosition={isMaxHeight}
          disabled={disabled || readOnly}
          setValue={updateDay}
        />
      </FormGroup>
      <FormGroup
        label={t('month')}
        className={`${styles.container__label} ${styles[`container__label--order-${formatMapping?.month}`]}`}
      >
        <SelectInput
          getOptionLabel={opt => t(`month_${opt}`)}
          getOptionValue={opt => opt}
          options={months}
          value={month + 1}
          disabled={disabled || readOnly}
          cardBg
          popLayout={!isMaxHeight}
          invertOptionsPosition={isMaxHeight}
          setValue={updateMonth}
        />
      </FormGroup>
      <FormGroup
        label={t('year')}
        className={`${styles.container__label} ${styles[`container__label--order-${formatMapping?.year}`]}`}
      >
        <SelectInput
          getOptionLabel={opt => String(opt)}
          getOptionValue={opt => opt}
          options={years}
          value={year}
          cardBg
          invertOptionsPosition={isMaxHeight}
          disabled={disabled || readOnly}
          popLayout={!isMaxHeight}
          setValue={updateYear}
        />
      </FormGroup>
    </div>
  );
}
