/* eslint-disable react/no-danger */
import { Image } from '@package/components';
import { SelectInputWithImageProps } from './props';
import styles from './styles.module.scss';

export function SelectInputWithImage({
  handleOpen,
  open,
  cardBg,
  isWithoutChevron,
  disabled,
  image,
  label,
  isSmall,
}: SelectInputWithImageProps) {
  function handleClick() {
    if (disabled) {
      return;
    }

    handleOpen();
  }

  return (
    <div
      className={`${styles.input} ${cardBg ? styles['input--card-bg'] : ''} ${disabled ? styles['input--disabled'] : ''} ${isSmall ? styles['input--small'] : ''}`}
      onClick={handleClick}
    >
      <div className={styles.input__wrapper}>
        {(label && (
          <Image
            format="square"
            width={32}
            height={32}
            src={image}
            alt="coin-image"
            className={styles.input__image}
            secondarySkeleton
          />
        )) || <div className={styles.input__image} />}
        <p dangerouslySetInnerHTML={{ __html: label }} />
        {!isWithoutChevron && !disabled && (
          <i
            className={`${styles.input__icon} ${open ? styles['input__icon--open'] : ''} fas fa-chevron-down`}
          />
        )}
        {disabled && <i className={`${styles.input__icon} fa-solid fa-lock`} />}
      </div>
    </div>
  );
}
