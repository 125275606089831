import {
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UseCustomCarouselReturn, UseCustomCarrouselProps } from './props';
import { useIsMobile } from '../useIsMobile';

export function useCustomCarousel(
  options?: UseCustomCarrouselProps,
): UseCustomCarouselReturn {
  const {
    onStepChange,
    size,
    autoScroll,
    timeout,
    currentIndex,
    scrollToCurrentOnEnter,
    slideBy = 1,
  } = options ?? {};

  const isMobile: boolean = useIsMobile();

  const isFirstMount: MutableRefObject<boolean> = useRef<boolean>(true);
  const containerRef: MutableRefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [hasScroll, setHasScroll] = useState<boolean>(false);
  const [hasScrollLeft, setHasScrollLeft] = useState<boolean>(false);
  const [hasScrollRight, setHasScrollRight] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const totalItems: number = containerRef.current
    ? containerRef.current.children.length
    : 0;
  const itemsPerView: number = isMobile ? 1 : (size ?? 1);

  const maxSteps: number = isMobile
    ? totalItems
    : Math.max(Math.ceil((totalItems - itemsPerView) / slideBy) + 1, 1);

  const handleMouseDown = useCallback((e: MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!isDragging) return;

      e.preventDefault();

      const x: number = e.pageX - e.currentTarget.offsetLeft;
      const walk: number = (x - startX) * 2;
      e.currentTarget.scrollLeft = scrollLeft - walk;
    },
    [isDragging, scrollLeft, startX],
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const checkIfHasScroll = useCallback(() => {
    const container: HTMLDivElement = containerRef.current;

    if (!container) {
      return;
    }

    setHasScroll(container.scrollWidth > container.clientWidth);
  }, []);

  const checkIfScrollableLeft = useCallback(() => {
    const container: HTMLDivElement = containerRef.current;

    if (!container) {
      return;
    }

    setHasScrollLeft(container.scrollLeft > 0);
  }, []);

  const checkIfScrollableRight = useCallback(() => {
    const container: HTMLDivElement = containerRef.current;

    if (!container) {
      return;
    }

    setHasScrollRight(
      container.scrollLeft < container.scrollWidth - container.clientWidth,
    );
  }, []);

  const handleDrag = useCallback(
    (direction: 'left' | 'right') => {
      const container: HTMLDivElement = containerRef.current;
      setIsDragging(false);

      if (!container) {
        return;
      }

      const childrenWidth =
        Number(container?.children?.[0]?.clientWidth ?? 220) + 16;
      let newScrollLeft: number;
      let newStep: number;
      const scrollWidth: number = childrenWidth * slideBy;

      switch (direction) {
        case 'left':
          newStep = Math.max(currentStep - 1, 0);
          newScrollLeft = container.scrollLeft - scrollWidth;
          break;
        case 'right':
          newStep = Math.min(currentStep + 1, maxSteps - 1);
          newScrollLeft = container.scrollLeft + scrollWidth;
          break;
      }

      container.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });

      setCurrentStep(newStep);
      setScrollLeft(newScrollLeft);
      onStepChange?.(newStep);
    },
    [containerRef, currentStep, maxSteps, onStepChange, slideBy],
  );

  const handleDragLeft = useCallback(() => {
    handleDrag('left');
  }, [handleDrag]);

  const handleDragRight = useCallback(() => {
    handleDrag('right');
  }, [handleDrag]);

  function scrollEventListener() {
    const container: HTMLDivElement = containerRef.current;

    checkIfHasScroll();

    function handleResize() {
      checkIfHasScroll();
    }

    function handleScroll() {
      checkIfScrollableLeft();
      checkIfScrollableRight();
      setScrollLeft(Number(container?.scrollLeft));
    }

    if (container) {
      container.addEventListener('scroll', handleScroll);
      checkIfScrollableLeft();
      checkIfScrollableRight();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);

      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }

  useEffect(scrollEventListener, [
    containerRef,
    isDragging,
    checkIfHasScroll,
    checkIfScrollableLeft,
    checkIfScrollableRight,
  ]);

  const handleStepListener = useCallback(() => {
    const container: HTMLDivElement = containerRef.current;

    if (!container) return;

    const childWidth: number = container.children[0]?.clientWidth + 16;

    const exactStep: number = scrollLeft / (childWidth * slideBy);
    const predictedStep: number = Math.round(exactStep);
    const boundedStep: number = Math.max(
      0,
      Math.min(predictedStep, maxSteps - 1),
    );

    if (currentStep === boundedStep) {
      return;
    }

    setCurrentStep(boundedStep);
    onStepChange?.(boundedStep);
  }, [currentStep, onStepChange, slideBy, maxSteps, scrollLeft]);

  useEffect(handleStepListener, [containerRef, handleStepListener]);

  const onStepClick = useCallback(
    (step: number) => {
      const container: HTMLDivElement = containerRef.current;

      if (!container) {
        return;
      }

      const childWidth: number =
        Number(container?.children?.[0]?.clientWidth) + 16;

      const scrollAmount: number = step * childWidth * slideBy;
      const maxScrollLeft: number =
        container.scrollWidth - container.clientWidth;
      const newScrollLeft: number = Math.min(scrollAmount, maxScrollLeft);

      setCurrentStep(step);
      setScrollLeft(newScrollLeft);
      onStepChange?.(step);

      container.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    },
    [onStepChange, slideBy],
  );

  const handleAutoScrollInterval = useCallback(() => {
    const container: HTMLDivElement = containerRef.current;

    if (!container) {
      return;
    }

    const childWidth: number = container?.children?.[0]?.clientWidth + 16;
    const scrollAmount: number = childWidth * slideBy;

    if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
      container.scrollTo({ left: 0, behavior: 'smooth' });
      setCurrentStep(0);
      return;
    }

    container.scrollTo({
      left: container.scrollLeft + scrollAmount,
      behavior: 'smooth',
    });
    setCurrentStep(prev => Math.min(prev + 1, maxSteps - 1));
  }, [slideBy, maxSteps]);

  const handleAutoScroll = useCallback(() => {
    if (!autoScroll) {
      return;
    }

    if (intervalId) {
      clearInterval(intervalId);
    }

    const id: NodeJS.Timeout = setInterval(
      handleAutoScrollInterval,
      timeout ?? 3500,
    );
    setIntervalId(id);

    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoScroll, handleAutoScrollInterval, timeout]);

  useEffect(handleAutoScroll, [autoScroll, handleAutoScroll]);

  function handleScrollToCurrentOnEnter() {
    if (
      !scrollToCurrentOnEnter ||
      !containerRef.current ||
      !currentIndex ||
      !isFirstMount.current
    ) {
      return;
    }

    isFirstMount.current = false;
    const childrenWidth: number =
      containerRef.current?.children?.[0]?.clientWidth + 16;
    containerRef.current.scrollTo({
      left: childrenWidth * currentIndex,
      behavior: 'smooth',
    });
  }

  useEffect(handleScrollToCurrentOnEnter, [
    currentIndex,
    scrollToCurrentOnEnter,
    handleDragRight,
  ]);

  return {
    containerRef,
    isDragging,
    handleMouseDown,
    handleMouseMove,
    handleMouseLeave,
    handleMouseUp,
    handleDragLeft,
    handleDragRight,
    hasScroll,
    hasScrollLeft,
    hasScrollRight,
    handleContainerScroll: checkIfHasScroll,
    scrollLeft,
    currentStep,
    onStepClick,
    steps: maxSteps,
  };
}
