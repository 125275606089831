import { useToggle } from '@package/hooks';
import { DatePickerProps } from '../props';
import { FieldValues } from 'react-hook-form';
import { Input } from '@package/components';

import baseStyles from '../styles.module.scss';
import { AnimatePresence } from 'framer-motion';
import DatePickerMobileSelector from './Selector';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

export default function DatePickerMobile<T extends FieldValues>({
  placeholder,
  onChange,
  value,
  disabled,
  readOnly,
  ...props
}: DatePickerProps<T>) {
  const [open, toggleOpen] = useToggle();
  const { lang } = useTranslation();

  return (
    <React.Fragment>
      <Input
        hasPaddingRight
        customIcon={
          <i
            className={`fa-solid fa-calendar ${baseStyles.icon}`}
            onClick={disabled || readOnly ? undefined : toggleOpen}
          />
        }
        onChange={e => onChange(e?.target?.value)}
        value={
          !!value
            ? Intl.DateTimeFormat(lang, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                timeZone: 'UTC',
              }).format(new Date(String(value)))
            : undefined
        }
        disabled={disabled}
        readOnly
        hideLock
        placeholder={placeholder}
        {...props}
      />
      <AnimatePresence mode="wait">
        {open && (
          <DatePickerMobileSelector
            {...props}
            toggleOpen={toggleOpen}
            onChange={onChange}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  );
}
