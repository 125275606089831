import { get } from '@package/utils';
import { FieldPath, FieldValues } from 'react-hook-form';

export function filterOptions<T extends FieldValues, V>(
  options: T[],
  value: V,
  filterBy: FieldPath<T>[],
): T[] {
  const filteredOptions: T[] = [];
  let formatedValue = `${value}`.trimEnd().trimStart();

  for (let i = 0; i < options.length; i++) {
    const option = options[i];

    for (let j = 0; j < filterBy.length; j++) {
      const filter = filterBy[j];
      const optionValue = get(option, filter);

      if (!optionValue) continue;

      if (
        `${optionValue}`.toLowerCase().startsWith(formatedValue.toLowerCase())
      ) {
        filteredOptions.unshift(option);
        break;
      }

      if (
        `${optionValue}`.toLowerCase().includes(formatedValue.toLowerCase())
      ) {
        filteredOptions.push(option);
        break;
      }
    }
  }

  return filteredOptions;
}
