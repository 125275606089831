import React from 'react';
import { useMemo } from 'react';
import { DatePickerMobileSelectorColumnProps } from './props';
import styles from './styles.module.scss';
import DatePickerMobileSelectorColumnCell from './Cell';
import { useScrollEffect } from '@package/src/hooks/date-picker/useScrollEffectHandler';
import useTranslation from 'next-translate/useTranslation';
import { Language } from '@package/src/enums/language';
import { dateFormatMapping } from '@package/src/constants/date/date-format-mapping';

export default function DatePickerMobileSelectorColumn<T>({
  content,
  onSelectedItem,
  currentIndex: current,
  type,
}: DatePickerMobileSelectorColumnProps<T>) {
  const itemHeight = 40;
  const { lang } = useTranslation();
  const formatMapping = dateFormatMapping.get(
    (lang as Language) ?? Language.English,
  );

  const {
    containerRef,
    getItemStyle,
    currentIndex,
    onMouseDown,
    onTouchStart,
  } = useScrollEffect({
    itemHeight,
    content,
    callback: onSelectedItem,
    current,
  });

  const Cells = useMemo(
    () =>
      content?.map((value, index) => (
        <DatePickerMobileSelectorColumnCell
          value={value}
          key={`date-picker-cell-${value}`}
          index={index}
          type={type}
          style={getItemStyle(index)}
          isCurrent={index == currentIndex}
        />
      )),
    [content, getItemStyle, currentIndex, type],
  );

  return (
    <div
      ref={containerRef}
      className={`${styles.container} ${styles[`container--order-${formatMapping?.[type]}`]}`}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
    >
      {Cells}
    </div>
  );
}
