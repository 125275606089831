import { useCallback, useEffect, useRef, useState } from 'react';
import { UseScrollEffectProps } from './props';

export function useScrollEffect<T>(options?: UseScrollEffectProps<T>) {
  const { callback, current, itemHeight = 40, content } = options ?? {};
  const totalItems = content?.length ?? 0;

  const containerRef = useRef<HTMLDivElement>(null);
  const previousYRef = useRef<number | null>(null);
  const offsetRef = useRef<number>(0);

  const [position, setPosition] = useState(current ? -current * itemHeight : 0);
  const [dragging, setDragging] = useState(false);
  const [snapping, setSnapping] = useState(false);

  const snapToClosest = useCallback(() => {
    if (snapping) return;
    setSnapping(true);

    const maxPosition = -(totalItems - 1) * itemHeight;
    const roundedPosition = Math.round(position / itemHeight) * itemHeight;
    const finalPosition = Math.max(maxPosition, Math.min(0, roundedPosition));
    setPosition(finalPosition);

    const newIndex = Math.abs(finalPosition / itemHeight);
    if (!callback || !content?.[newIndex]) {
      setSnapping(false);
      return;
    }
    callback(content[newIndex] as T);

    setSnapping(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemHeight, position, totalItems, snapping]);

  const onDragStart = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ) => {
    setDragging(true);

    const clientY =
      'touches' in event ? event.touches[0].clientY : event.clientY;
    previousYRef.current = clientY;
  };

  const onDragMove = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!dragging || previousYRef.current === null) {
        return;
      }

      const clientY =
        'touches' in event
          ? (event as TouchEvent).touches[0].clientY
          : (event as MouseEvent).clientY;

      const moveOffset = clientY - previousYRef.current;

      setPosition(prev => {
        const maxPosition = -(totalItems - 1) * itemHeight;
        const newPosition = Math.max(
          maxPosition,
          Math.min(0, prev + moveOffset),
        );
        offsetRef.current = moveOffset;
        return newPosition;
      });

      previousYRef.current = clientY;
    },
    [dragging, itemHeight, totalItems],
  );

  const onDragEnd = useCallback(() => {
    setDragging(false);
    previousYRef.current = null;
    snapToClosest();
  }, [snapToClosest]);

  function handleScrollListeners() {
    if (typeof window == 'undefined') {
      return;
    }

    if (dragging) {
      window.addEventListener('mousemove', onDragMove);
      window.addEventListener('mouseup', onDragEnd);
      window.addEventListener('touchmove', onDragMove);
      window.addEventListener('touchend', onDragEnd);
    } else {
      window.removeEventListener('mousemove', onDragMove);
      window.removeEventListener('mouseup', onDragEnd);
      window.removeEventListener('touchmove', onDragMove);
      window.removeEventListener('touchend', onDragEnd);
    }

    return () => {
      window.removeEventListener('mousemove', onDragMove);
      window.removeEventListener('mouseup', onDragEnd);
      window.removeEventListener('touchmove', onDragMove);
      window.removeEventListener('touchend', onDragEnd);
    };
  }

  useEffect(handleScrollListeners, [dragging, onDragEnd, onDragMove]);

  function getItemStyle(index: number) {
    const offset = Math.abs(position / itemHeight + index);
    const scale = 1 - offset * 0.1;
    const opacity = 1 - offset * 0.4;

    return {
      willChange: 'transform',
      transition: `transform ${Math.abs(offset) / 100 + 0.1}s`,
      transform: `translateY(${position}px) scale(${scale})`,
      opacity,
    };
  }

  return {
    currentIndex: Math.abs(position / itemHeight),
    containerRef,
    getItemStyle,
    onMouseDown: onDragStart,
    onTouchStart: onDragStart,
  };
}
