import { FieldValues } from 'react-hook-form';
import { DatePickerMobileSelectorProps } from './props';
import { m as motion } from 'framer-motion';
import { slideIn } from './variants';
import styles from './styles.module.scss';
import { useClickOutside, useCustomDatePicker } from '@package/hooks';
import { ModalHeader } from '@package/components';
import DatePickerMobileSelectorColumn from './Column';

export default function DatePickerMobileSelector<T extends FieldValues>({
  toggleOpen,
  placeholder,
  isLoading,
  value,
  ...props
}: DatePickerMobileSelectorProps<T>) {
  const {
    days,
    years,
    months,
    updateDay,
    updateMonth,
    updateYear,
    current: { day, month, year },
  } = useCustomDatePicker({
    date: new Date((value as string) ?? new Date()).toString(),
    callback: props?.onChange,
    isLoading: props?.showSpinner || isLoading,
  });

  const ref = useClickOutside({
    onClickOutside: toggleOpen,
    open: true,
  });

  return (
    <motion.div
      className={styles.container}
      variants={slideIn}
      initial="hidden"
      animate="visible"
      exit="hidden"
      ref={ref}
    >
      <ModalHeader title={placeholder} onClose={toggleOpen} />
      <div className={styles.container__content}>
        <DatePickerMobileSelectorColumn
          content={days}
          currentIndex={day + 1}
          onSelectedItem={updateDay}
          type="day"
        />
        <DatePickerMobileSelectorColumn
          content={months}
          currentIndex={month}
          onSelectedItem={updateMonth}
          type="month"
        />
        <DatePickerMobileSelectorColumn
          content={years}
          currentIndex={year}
          onSelectedItem={updateYear}
          type="year"
        />
      </div>
    </motion.div>
  );
}
