import { SelectInputProps } from './props';
import { useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import SelectInputOptions from './Options';
import styles from './styles.module.scss';
import skeleton from './styles-skeleton.module.scss';
import { useClickOutside } from '@package/hooks';
import SelectInputOptionInput from './Input';

export function SelectInput<T, V>({
  options = [],
  setValue,
  getOptionLabel,
  getOptionValue,
  value,
  disabled,
  cardBg,
  secondaryText = false,
  loading = false,
  loadingMore = false,
  invertOptionsPosition = false,
  loadMore = () => {},
  hasNextPage = false,
  isAsync = false,
  customComponents,
  error,
  refetch,
  disableClickOutside,
  popLayout,
}: SelectInputProps<T, V>) {
  const ref = useRef<HTMLDivElement>();
  const [open, setOpen] = useState<boolean>(false);

  function handleToggleFilterDropdown() {
    setOpen(state => !state);
  }

  useClickOutside({
    open,
    onClickOutside: handleToggleFilterDropdown,
    customRef: ref,
    disabled: disableClickOutside,
  });

  if (loading) {
    return customComponents?.customSkeletonInput ? (
      customComponents?.customSkeletonInput()
    ) : (
      <div
        className={`${styles['selected-option']} ${cardBg ? styles['selected-option--card-bg'] : ''}`}
      >
        <div className={skeleton.text} />
        <div className={skeleton.chevron} />
      </div>
    );
  }

  return (
    <div className={styles.container} ref={ref} id="select-input-container">
      <SelectInputOptionInput
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        handleToggleFilterDropdown={handleToggleFilterDropdown}
        options={options}
        setValue={setValue}
        value={value}
        cardBg={cardBg}
        customComponents={customComponents}
        error={error}
        refetch={refetch}
        hasNextPage={hasNextPage}
        secondaryText={secondaryText}
        isAsync={isAsync}
        open={open}
        disableClickOutside={disableClickOutside}
        disabled={disabled}
        loadMore={loadMore}
        loading={loading}
        loadingMore={loadingMore}
        popLayout={popLayout}
      />
      <AnimatePresence>
        {open && !disabled && (
          <SelectInputOptions
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            handleClose={handleToggleFilterDropdown}
            options={options}
            parentBounding={ref?.current?.getBoundingClientRect?.()}
            setValue={setValue}
            value={value}
            cardBg={cardBg}
            customComponents={customComponents}
            error={error}
            refetch={refetch}
            hasNextPage={hasNextPage}
            isAsync={isAsync}
            isInverted={invertOptionsPosition}
            loadMore={loadMore}
            popLayout={popLayout}
            loading={loading}
            loadingMore={loadingMore}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
