import { memo } from 'react';
import { TabProps } from './props';
import styles from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { ToolTip } from '@package/components';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';

function Tab<T>({
  tab,
  setTab,
  isTiny,
  version,
  isSmall,
  disabled,
  currentTab,
  useMaxContent,
  i18nNamespace,
}: TabProps<T>) {
  const { t } = useTranslation(!!i18nNamespace ? i18nNamespace : 'common');
  const { t: tCommon } = useTranslation('common');
  const isActive = currentTab === tab?.value;

  function handleClick() {
    if (tab?.inactive) {
      return;
    }

    setTab(tab?.value);
  }

  return (
    <ToolTip
      visible={!!tab?.inactive}
      titleComponent={tCommon('option_unavailable')}
    >
      <button
        className={`${styles.button} ${isTiny ? styles['button--tiny'] : ''} ${isActive ? styles['button--active'] : ''} ${isSmall ? styles['button--small'] : ''} ${isActive && isSmall ? styles['button--small--active'] : ''} ${version === SettingLayoutComponentId.TabsV3 ? styles['button--container'] : ''} ${!useMaxContent ? styles['button--max-content'] : ''} ${version === SettingLayoutComponentId.TabsV1 ? styles['button--with-indicator'] : ''} ${tab?.inactive ? styles['button--disabled'] : ''}`}
        onClick={handleClick}
        type="button"
        id={`tab-button-${tab?.value}`}
        disabled={disabled}
      >
        <div className={styles.button__content}>
          {tab?.icon && <i className={tab?.icon} />}
          {t(tab?.label, {
            app_provider: process.env.NEXT_PUBLIC_BET_NAME,
          })}
        </div>
      </button>
    </ToolTip>
  );
}

export default memo(Tab) as typeof Tab;
