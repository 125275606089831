import { useState, useEffect, useCallback } from 'react';
import { UseCountdownProps } from './props';

export function useCountdown({
  disabled,
  targetDate,
  onCountdownComplete,
}: UseCountdownProps) {
  const calculateTimeLeft = useCallback(
    (clearTimer?: () => void) => {
      const now = new Date().getTime();
      const targetTime = new Date(targetDate).getTime();
      const timeLeft = targetTime - now;

      let days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
        .toString()
        .padStart(2, '0');
      let hours = Math.floor(
        (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
        .toString()
        .padStart(2, '0');
      let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
        .toString()
        .padStart(2, '0');
      let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, '0');

      if (
        Number(days) <= 0 &&
        Number(hours) <= 0 &&
        Number(minutes) <= 0 &&
        Number(seconds) <= 0
      ) {
        days = '00';
        hours = '00';
        minutes = '00';
        seconds = '00';

        clearTimer?.();

        if (onCountdownComplete || !disabled) {
          onCountdownComplete?.();
        }
      }

      return {
        days,
        hours,
        minutes,
        seconds,
        timeLeft,
      };
    },
    //eslint-disable-next-line
    [targetDate],
  );

  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft());
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (disabled) return;

    const totalDuration = new Date(targetDate).getTime() - new Date().getTime();

    const timer = setInterval(() => {
      const { timeLeft: currentTimeLeft } = calculateTimeLeft(() =>
        clearInterval(timer),
      );
      setTimeLeft(calculateTimeLeft(() => clearInterval(timer)));

      const calculatedProgress =
        ((totalDuration - currentTimeLeft) / totalDuration) * 100;
      setProgress(calculatedProgress);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [disabled, calculateTimeLeft, targetDate]);

  return {
    ...timeLeft,
    progress,
    isCounting:
      timeLeft?.days != '00' ||
      timeLeft?.hours != '00' ||
      timeLeft?.minutes != '00' ||
      timeLeft?.seconds != '00',
  };
}
