
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import HomeAreas from '@/components/Home/Areas';
import HomeGamesSections from '@/components/Home/Games';
import HomeHero from '@/components/Home/Hero';
import HomePaymentProviders from '@/components/Home/PaymentProviders';
import HomeProviders from '@/components/Home/Providers';
import HomeSolutions from '@/components/Home/Solutions';
import WorkWithUsSection from '@/components/Home/WorkWithUs';
import Layout from '@/components/Layout';
import { Language } from '@/i18n/languages';
import { generateMetadata } from '@/utils/metadata';
import { Animation } from '@package/components';
import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
export default function Home() {
    return (<Layout>
      <HomeHero />
      <Animation type="fadeInUp" triggerOnce>
        <HomeSolutions />
      </Animation>
      <Animation type="fadeInLeft" triggerOnce>
        <HomeAreas />
      </Animation>
      <Animation type="fadeInRight" triggerOnce>
        <HomeProviders />
      </Animation>
      <Animation type="fadeInUp" triggerOnce>
        <HomeGamesSections />
      </Animation>
      <Animation type="fadeInLeft" triggerOnce>
        <HomePaymentProviders />
      </Animation>
      <WorkWithUsSection />
    </Layout>);
}
async function getStaticProps({ locale, }: GetStaticPropsContext): Promise<GetStaticPropsResult<{
    metadata: string;
}>> {
    const metadata = await generateMetadata({
        lang: locale as Language,
        path: '/',
        description: 'home_description',
        follow: true,
        title: 'home_heading',
    });
    return {
        props: {
            metadata: JSON.stringify(metadata),
        },
    };
}

    async function __Next_Translate__getStaticProps__1927d47dd4d__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1927d47dd4d__ as getStaticProps }
  