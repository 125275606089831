import useTranslation from 'next-translate/useTranslation';
import { DatePickerMobileSelectorColumnCellProps } from './props';
import styles from './styles.module.scss';
import { getDatePickerOptionLabel } from './helper';
import { memo } from 'react';

function DatePickerMobileSelectorColumnCell<T>({
  value,
  isCurrent,
  style,
  type,
}: DatePickerMobileSelectorColumnCellProps<T>) {
  const { t } = useTranslation('common');
  const label = getDatePickerOptionLabel({ type }, value) as string;
  return (
    <div
      className={`${styles.cell} ${isCurrent ? styles['cell--current'] : ''}`}
      style={style}
    >
      {type == 'month' ? t(label) : label}
    </div>
  );
}

export default memo(
  DatePickerMobileSelectorColumnCell,
) as typeof DatePickerMobileSelectorColumnCell;
