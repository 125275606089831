import { FieldValues } from 'react-hook-form';
import { DatePickerProps } from './props';
import { useIsMobile } from '@package/hooks';
import DatePickerMobile from './Mobile';
import DatePickerDesktop from './Desktop';

export function DatePicker<T extends FieldValues>({
  placeholder,
  onChange,
  value,
  disabled,
  readOnly,
  ...props
}: DatePickerProps<T>) {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <DatePickerMobile
        {...props}
        onChange={onChange}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
      />
    );
  }

  return (
    <DatePickerDesktop
      {...props}
      onChange={onChange}
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      placeholder={placeholder}
    />
  );
}
