import { Language } from '@package/src/enums/language';

export const dateFormatMapping: Map<
  Language,
  { day: number; month: number; year: number }
> = new Map([
  [Language.Portuguese, { day: 1, month: 2, year: 3 }],
  [Language.Spanish, { day: 1, month: 2, year: 3 }],
  [Language.French, { day: 1, month: 2, year: 3 }],
  [Language.English, { day: 2, month: 1, year: 3 }],
  [Language.Chinese, { day: 2, month: 1, year: 3 }],
  [Language.Russian, { day: 1, month: 2, year: 3 }],
  [Language.Dutch, { day: 1, month: 2, year: 3 }],
  [Language.Indonesian, { day: 1, month: 2, year: 3 }],
]);
