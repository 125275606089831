/* eslint-disable no-redeclare */
import { FieldPath, FieldValues } from 'react-hook-form';
import { GroupByProps } from './props';
import { get } from '@package/utils';

export function groupBy<T extends FieldValues, K extends FieldPath<T>>(
  props: GroupByProps<T, K>,
): Map<T[K], T[]>;
export function groupBy<
  T extends FieldValues,
  K extends FieldPath<T>,
  VK extends FieldPath<T>,
>(props: GroupByProps<T, K, VK>): Map<T[K], T[VK][]>;
export function groupBy<
  T extends FieldValues,
  K extends FieldPath<T>,
  VK extends FieldPath<T>,
>({ key, records, valueKey }: GroupByProps<T, K, VK>): Map<T[K], any> {
  const groupedRecords = new Map<T[K], any>();

  for (const record of records) {
    const recordKeyValue: T[K] = get(record, key);

    if (!groupedRecords.has(recordKeyValue)) {
      groupedRecords.set(recordKeyValue, []);
    }

    const mapValue: T | T[VK] = valueKey ? get(record, valueKey) : record;

    groupedRecords.set(
      recordKeyValue,
      (groupedRecords.get(recordKeyValue) ?? []).concat(mapValue),
    );
  }

  return groupedRecords;
}
