import { MouseEvent, PropsWithChildren } from 'react';
import { PageDrawerProps } from './props';
import styles from './styles.module.scss';
import { AnimatePresence, m } from 'framer-motion';
import { pageDrawerVariant } from './variants';
import { useClickOutside } from '@package/hooks';
import { ModalHeader } from '@package/components';

export function PageDrawer({
  isOpen,
  onClose,
  children,
  title,
  paddingless,
}: PropsWithChildren<PageDrawerProps>) {
  const { current } = useClickOutside({
    onClickOutside: onClose,
    open: isOpen,
  });

  function handleContainer(event: MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
  }

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <div className={styles.container__overlay} onClick={onClose}>
          <m.div
            variants={pageDrawerVariant}
            initial="hidden"
            animate="open"
            onClick={handleContainer}
            exit="hidden"
            className={styles.container}
            ref={current}
          >
            <ModalHeader onClose={onClose} title={title} withoutPadding />

            <div
              className={`${styles.container__content} ${paddingless ? styles['container__content--paddingless'] : ''}`}
            >
              {children}
            </div>
          </m.div>
        </div>
      )}
    </AnimatePresence>
  );
}
