import useTranslation from 'next-translate/useTranslation';
import { StepComponentProps } from './props';

import styles from './styles.module.scss';
import { memo } from 'react';

function Step<V>({
  currentStep,
  i18nWorkspace,
  option,
  blockFurtherSteps,
  index,
  currentIndex,
  isLoading,
  onStepClick,
}: StepComponentProps<V>) {
  const { t } = useTranslation(i18nWorkspace);
  const isCurrent = currentIndex <= index;

  function handleClick() {
    if (typeof onStepClick != 'undefined') {
      onStepClick(option?.value);
    }
  }

  if (isLoading) {
    return (
      <div className={styles.step}>
        <div className={styles['step__indicator__skeleton']} />
        <div className={styles['step__label__skeleton']} />
      </div>
    );
  }

  return (
    <div
      onClick={handleClick}
      className={`${styles.step} ${blockFurtherSteps ? (isCurrent ? styles['step--disabled'] : '') : ''}`}
      key={`step-${option.label}`}
    >
      <div
        className={`${styles['step__label']} ${option.value == currentStep ? styles['step__label--active'] : ''}`}
      >
        {t(option.label)}
      </div>
      <div
        className={`${styles.step__marker} ${option?.value == currentStep || option?.isCompleted ? styles['step__marker--completed'] : ''}`}
      />
    </div>
  );
}

export default memo(Step) as typeof Step;
