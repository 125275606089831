import { useState, useCallback, useMemo, useEffect } from 'react';
import { UseCustomDatePickerProps } from './props';

export function useCustomDatePicker(options?: UseCustomDatePickerProps) {
  const { date: initialDate, callback, isLoading } = options ?? {};
  const [isDateModified, setIsDateModified] = useState(false);

  const [date, setDate] = useState<Date>(
    initialDate ? new Date(initialDate) : new Date(),
  );

  function handleResetInitialDate() {
    if (!isLoading && !isDateModified) {
      setDate(new Date(initialDate));
    }
  }

  useEffect(handleResetInitialDate, [isLoading, initialDate, isDateModified]);

  const month = date.getMonth();
  const year = date.getFullYear();
  const day = date.getUTCDate();

  const getMaxDaysForMonth = useCallback(
    (year: number, month: number) => new Date(year, month + 1, 0).getDate(),
    [],
  );

  const daysInMonth = useMemo(
    () => getMaxDaysForMonth(year, month),
    [year, month, getMaxDaysForMonth],
  );

  const months = useMemo(() => Array.from({ length: 12 }, (_, i) => i + 1), []);

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 100 }, (_, i) => currentYear - i);
  }, []);

  const yearIndex = useMemo(
    () => years.findIndex(y => y === year),
    [years, year],
  );

  const updateDay = useCallback(
    (newDay: number) => {
      setDate(prev => {
        const updatedDate = new Date(
          prev.getFullYear(),
          prev.getMonth(),
          newDay,
        );
        callback?.(updatedDate.toISOString());
        return updatedDate;
      });
      setIsDateModified(true);
    },
    [callback],
  );

  const updateMonth = useCallback(
    (newMonth: number) => {
      setDate(prev => {
        const maxDays = getMaxDaysForMonth(prev.getFullYear(), newMonth - 1);
        const adjustedDay = Math.min(prev.getDate(), maxDays);
        const updatedDate = new Date(
          prev.getFullYear(),
          newMonth - 1,
          adjustedDay,
        );
        callback?.(updatedDate.toISOString());
        return updatedDate;
      });
      setIsDateModified(true);
    },
    [callback, getMaxDaysForMonth],
  );

  const updateYear = useCallback(
    (newYear: number) => {
      setDate(prev => {
        const maxDays = getMaxDaysForMonth(newYear, prev.getMonth());
        const adjustedDay = Math.min(prev.getDate(), maxDays);
        const updatedDate = new Date(newYear, prev.getMonth(), adjustedDay);
        callback?.(updatedDate.toISOString());
        return updatedDate;
      });
      setIsDateModified(true);
    },
    [callback, getMaxDaysForMonth],
  );

  const days = useMemo(
    () => Array.from({ length: daysInMonth }).map((_, index) => index + 1),
    [daysInMonth],
  );

  return {
    date,
    days,
    months,
    years,
    updateDay,
    updateMonth,
    updateYear,
    current: {
      day: day - 1,
      month,
      year: yearIndex,
      currentYear: year,
    },
  };
}
