import { Transition, Variants } from 'framer-motion';

const transition: Transition = { duration: 0.3, ease: 'easeInOut' };

export const slideIn: Variants = {
  hidden: {
    y: 1000,
    pointerEvents: 'none',
    transition,
  },
  visible: {
    y: 0,
    transition,
    pointerEvents: 'all',
  },
};
