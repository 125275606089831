import { useCallback, useState } from 'react';
import EnhancedTableHead from './Head';
import { Order, TableGridProps } from './props';
import TableGridContent from './Content';
import { FieldPath, FieldValues } from 'react-hook-form';
import styles from './styles.module.scss';
import useSteppedAnimation from '@package/src/hooks/useTableAnimation';

export function TableGrid<T extends FieldValues>({
  rows,
  columns,
  useLargerRows,
  isLoading,
  isSecondaryStyles,
  useAutoScroll = false,
  preventOrder,
  handleRedirect,
}: TableGridProps<T>) {
  const [order, setOrder] = useState<Order>(Order.ASC);
  const [orderBy, setOrderBy] = useState<FieldPath<T> | null>(null);
  const { ref, endAnimation, currentStep } = useSteppedAnimation(
    1000,
    Boolean(useAutoScroll),
    Boolean(isLoading),
  );

  const handleRequestSort = useCallback(
    (order: Order, property: FieldPath<T>) => {
      if (preventOrder) {
        return;
      }

      const isAsc = orderBy === property && order === Order.ASC;
      setOrder(isAsc ? Order.DESC : Order.ASC);
      setOrderBy(property);
    },
    [orderBy, preventOrder],
  );

  return (
    <div className={styles.container}>
      <div
        className={`${styles['container__table-container']} ${rows.length > 0 && isSecondaryStyles ? styles['container__table-container--secondary'] : ''}`}
      >
        <table
          ref={ref}
          className={`${styles['container__table-container__table']} ${rows.length > 0 && isSecondaryStyles ? styles['container__table-container__table--secondary'] : ''} ${isLoading || endAnimation || currentStep < 2 ? styles['container__table-container__table--no-transition'] : ''}`}
        >
          <EnhancedTableHead
            columns={columns}
            handleOrder={handleRequestSort}
            order={order}
            isSecondaryStyles={isSecondaryStyles}
            orderBy={orderBy}
            preventOrder={preventOrder}
          />
          <tbody
            style={{
              //@ts-ignore
              '--duration': `${rows.length}s`,
            }}
            className={`${styles['container__table-container__body']} ${rows.length > 0 && isSecondaryStyles ? styles['container__table-container__body--secondary'] : ''}`}
          >
            <TableGridContent
              columns={columns}
              order={order}
              useLargerRows={useLargerRows}
              orderBy={orderBy}
              rows={rows}
              isLoading={isLoading}
              isSecondaryStyles={isSecondaryStyles}
              handleRedirect={handleRedirect}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
}
